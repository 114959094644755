.pinCode input[type='checkbox']:before {
  content: '';
  position: absolute;
  width: 16px;
  height: 16px;
  border-radius: 16px;
  top: 0;
  left: 0;
  background-color: #9c9da3;
  transform: scale(1.2);
  transition: 0.5s;
}

.pinCode input:checked[type='checkbox']:before {
  left: 16px;
  background-color: #4251da;
}
