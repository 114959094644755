.rdrCalendarWrapper,
.rdrMonth {
  @apply !w-full !max-w-md;
}

.rdrDateDisplayWrapper {
  @apply hidden;
}

.rdrCalendarWrapper {
  @apply flex;
}

.rdrMonthPicker,
.rdrYearPicker {
  @apply rounded focus-within:border-primary-60 sm:border sm:border-solid sm:border-text-50;
}

.rdrMonthAndYearWrapper {
  @apply mb-4 sm:mt-2;
}

.rdrMonthAndYearPickers {
  @apply text-sm font-medium leading-5 text-text-100;
}

.rdrMonthAndYearPickers select {
  @apply !bg-none py-2 pl-3 pr-11 text-[1.125rem] font-bold text-text-100 focus:!bg-white sm:bg-white sm:font-normal;
}

.rdrMonthAndYearPickers select option {
  @apply bg-white text-left text-base leading-5 text-text-50;
}

.rdrNextPrevButton,
.rdrNextButton {
  @apply !bg-white;
}

.rdrMonth {
  @apply mt-2;
}

.rdrDay {
  @apply !text-transparent;
}

.rdrSelected {
  background-color: #4251da;
  margin-inline: 10px;
  border-radius: 36px !important;
}

@media (min-width: 640px) {
  .rdrSelected {
    margin-inline: 16px;
  }
}

.rdrDayStartPreview span {
  color: white !important;
}

.rdrDayStartPreview {
  color: white !important;
}

.rdrDayToday .rdrDayNumber span:after {
  background: transparent;
}

.rdrWeekDay {
  @apply text-base uppercase leading-5 text-text-100;
}

.rdrMonthName {
  @apply !py-0 text-[1.125rem] leading-5 text-text-100;
  letter-spacing: 0.25px;
}

.custom-calendar .rdrMonthPicker,
.custom-calendar .rdrYearPicker {
  max-height: 150px !important;
  overflow-y: auto !important;
  display: block;
}

.custom-calendar .rdrMonthPicker select,
.custom-calendar .rdrYearPicker select {
  transform-origin: top;
  transform: translateY(calc(-100% + 25x));
}
