.rdrInRange {
  left: -17px;
  right: -17px;
  background-color: #4251da;
}

.rdrDay:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span {
  color: #fff !important;
}

.rdrDayNumber span {
  @apply text-text-100;
}

.rdrStartEdge {
  top: 2px !important;
  bottom: 2px !important;
  right: 8px !important;
  left: 8px !important;
  border-radius: 36px !important;
  background-color: #4251da;
}

.rdrEndEdge {
  top: 2px !important;
  bottom: 2px !important;
  right: 8px !important;
  left: 8px !important;
  border-radius: 36px !important;
  background-color: #4251da;
}

@media (min-width: 640px) {
  .rdrStartEdge {
    top: 0px;
    bottom: 0px;
    right: 12px !important;
    left: 12px !important;
    border-radius: 36px !important;
  }
  .rdrEndEdge {
    top: 0px;
    bottom: 0px;
    right: 12px !important;
    left: 12px !important;
    border-radius: 36px !important;
  }
}

.rdrDayToday .rdrDayNumber span::after {
  content: none;
}
