@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  @apply font-roboto text-xs md:text-base;
}

svg {
  display: inline-block;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  @apply font-roboto font-bold;
}

h1 {
  @apply mb-4 text-2xl leading-[120%];
}

h3 {
  @apply mb-4 text-base leading-[120%] md:text-lg;
}

h6 {
  @apply mb-1 font-roboto text-xs font-normal leading-[120%] text-gray-medium md:text-sm;
}

label {
  @apply font-roboto;
}

.input-default {
  all: unset;
}

.nav-menu .active svg > path {
  fill: black;
}

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.sidebar-menu a:hover svg,
.sidebar-menu a:hover i {
  @apply text-black;
}

.grid-container {
  display: grid;
  grid-template-columns: minmax(0, min-content) 1fr;
  grid-template-rows: 40px 1fr;
  grid-template-areas:
    'header header'
    'aside main';
  column-gap: 8px;
}

.grid-order-card {
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 75px 140px 110px 120px 110px 1fr;
}

@media screen and (max-width: 767px) {
  .grid-container {
    display: block;
  }
}

main {
  grid-area: main;
}

header {
  grid-area: header;
}

footer {
  grid-area: footer;
}

aside {
  grid-area: aside;
}

@layer utilities {
  .leading-cz {
    line-height: 1.1666667; /* если font-size 12px, то line-height 14px */
  }

  .large-title-normal,
  .large-title-bold {
    @apply text-[1.75rem];
  }

  .large-title-normal {
    @apply font-normal;
  }

  .large-title-bold {
    @apply font-bold;
  }

  .large-title2-bold {
    @apply text-2xl font-bold;
  }

  .title-bold,
  .title-medium,
  .title-normal {
    @apply text-[1.375rem];
    letter-spacing: 0.25px;
  }

  .title-normal {
    @apply font-normal;
  }

  .title-medium {
    @apply font-medium;
  }

  .title-bold {
    @apply font-bold;
  }

  .title2-bold,
  .title2-medium,
  .title2-normal {
    @apply text-xl;
    letter-spacing: 0.25px;
  }

  .title2-normal {
    @apply font-normal;
  }

  .title2-medium {
    @apply font-medium;
  }

  .title2-bold {
    @apply font-bold;
  }

  .headline-bold,
  .headline-medium,
  .headline-normal {
    @apply text-[1.125rem];
    letter-spacing: 0.25px;
  }

  .headline-normal {
    @apply font-normal;
  }

  .headline-medium {
    @apply font-medium;
  }

  .headline-bold {
    @apply font-bold;
  }

  .body-bold,
  .body-medium,
  .body-normal {
    @apply text-base leading-5 text-text-100;
  }

  .body-normal {
    @apply font-normal;
    letter-spacing: 0.15px;
  }

  .body-medium {
    @apply font-medium;
  }

  .body-bold {
    @apply font-bold;
  }

  .body-paragraphs-bold,
  .body-paragraphs-medium,
  .body-paragraphs-normal {
    @apply text-base leading-6;
    letter-spacing: 0.5px;
  }

  .body-paragraphs-normal {
    @apply font-normal;
  }

  .body-paragraphs-medium {
    @apply font-medium;
  }

  .body-paragraphs-bold {
    @apply font-bold;
  }

  .callout-bold,
  .callout-medium,
  .callout-normal {
    @apply text-sm text-text-100;
    letter-spacing: 0.1px;
  }

  .callout-normal {
    @apply font-normal leading-5;
  }

  .callout-medium {
    @apply font-medium leading-5;
  }

  .callout-bold {
    @apply font-bold leading-4;
  }

  .callout-paragraphs-bold,
  .callout-paragraphs-medium,
  .callout-paragraphs-normal {
    @apply text-sm;
    letter-spacing: 0.1px;
    line-height: 1.125rem;
  }

  .callout-paragraphs-normal {
    @apply font-normal;
  }

  .callout-paragraphs-medium {
    @apply font-medium;
  }

  .callout-paragraphs-bold {
    @apply font-bold;
  }

  .footnote-bold,
  .footnote-medium,
  .footnote-normal {
    @apply text-xs leading-4;
    letter-spacing: 0.4px;
  }

  .footnote-normal {
    @apply font-normal;
  }

  .footnote-medium {
    @apply font-medium;
  }

  .footnote-bold {
    @apply font-bold;
  }

  .caption-bold,
  .caption-medium,
  .caption-normal {
    @apply text-[0.625rem] leading-4;
    letter-spacing: 0.4px;
  }

  .caption-normal {
    @apply font-normal;
  }

  .caption-medium {
    @apply font-medium;
  }

  .caption-bold {
    @apply font-bold;
  }

  .gradient-0 {
    background: theme(gradients.gradient-0);
  }
  .gradient-20 {
    background: theme(gradients.gradient-20);
  }
}

@layer components {
  .tag {
    @apply w-fit self-center rounded-sm px-1 py-0.5 text-xs leading-4;
  }

  .responsive-p {
    @apply px-4 pt-4;
  }

  .fixed-container {
    @apply fixed top-0 left-0 w-full;
  }

  .without-scroll {
    @apply overflow-hidden;
  }

  .btn-base {
    @apply body-paragraphs-bold h-14 min-w-[10rem] max-w-[20rem] truncate rounded-lg px-8 py-4 text-center transition-[background] delay-100;
  }

  .error-message {
    @apply leading-cz mt-1.5 text-xs text-red;
  }

  .input {
    @apply block w-full border-0 border-b-2 border-solid border-light-50 bg-white pb-[4px] text-text-100 !outline-none focus:border-primary-60 disabled:text-gray-medium;
  }
  .input-filter {
    @apply block w-full border-b border-gray bg-white p-2.5 text-base font-semibold outline-none placeholder:font-normal placeholder:text-gray-medium focus:border-[#203F9A];
  }

  .input-error {
    @apply border-0 border-b !border-red !outline-none focus:border-red !important;
  }

  .textarea-error {
    @apply border-red !outline-none focus:border-red !important;
  }

  .textarea {
    @apply headline-normal block w-full resize-none rounded-xl border border-light-30 bg-white p-2 disabled:resize-none disabled:text-gray-medium;
  }

  .label {
    @apply leading-cz body-normal mb-1.5 block !text-text-50;
  }

  .fake-radio {
    @apply mr-2 flex h-5 w-5 shrink-0 items-center justify-center rounded-full border text-text-50 focus:shadow-input;
  }

  .fake-radio:hover {
    @apply after:bg-teal-dark;
  }

  .check-hover:hover span {
    @apply after:border-primary-60;
  }
}

.reset-scrollbar-styles::-webkit-scrollbar {
  width: 0;
}

.reset-scrollbar-styles::-webkit-scrollbar-thumb {
  width: 0;
}

.default-scrollbar-styles::-webkit-scrollbar {
  @apply h-1;
}

.default-scrollbar-styles::-webkit-scrollbar-track {
  /* box-shadow: inset 0 0 5px #d9d9d9; */
  /* border-radius: 10px; */
}

.default-scrollbar-styles::-webkit-scrollbar-thumb {
  @apply rounded bg-primary-60;
}

.default-scrollbar-styles::-webkit-scrollbar-thumb:hover {
  @apply bg-primary-60;
}

::-webkit-scrollbar {
  @apply h-1 w-1;
}

::-webkit-scrollbar-track {
  /* box-shadow: inset 0 0 5px #d9d9d9; */
  /* border-radius: 10px; */
}

::-webkit-scrollbar-thumb {
  @apply rounded bg-primary-60;
}

::-webkit-scrollbar-thumb:hover {
  @apply bg-primary-60;
}

/* .default-scrollbar-styles::-webkit-scrollbar {
  width: 10px;
  height: 4px;
}

.default-scrollbar-styles::-webkit-scrollbar-thumb {
  width: 10px;
  height: 4px;
} */

.styled-scrollbar::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

.styled-scrollbar::-webkit-scrollbar-thumb {
  @apply bg-primary-60;
  border-radius: 6px;
}

.hide-scrollbar {
  scrollbar-width: none;
}

.hide-scrollbar::webkit-scrollbar {
  display: none;
}

.react-pdf__Page {
  display: flex !important;
  justify-content: center !important;
}

.react-pdf__Page__canvas {
  max-width: 100% !important;
  height: auto !important;
}

.react-select-multi-value > div {
  background-color: transparent !important;
}